import Layout from "../components/layout"
import * as React from "react"
import { Helmet } from "react-helmet"

const ImprintPage = props => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - Imprint</title>
        <link rel="canonical" href="https://www.kalbytes.de/" />
      </Helmet>
      <div className="data-privacy-impressum-page">
        <div class="data-privacy-impressum-page_container">
          <div>
            <div class="description">
              <div>
                <div class="row">
                  <div>
                    <div class="">
                      <div class="page-heading">
                        <h1 class="custom_title">Impressum</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div>
                  <div class="page-content">
                    <h2>Angaben gemäß § 5 TMG:</h2>

                    <p>
                      KalBytes GmbH
                      <br />
                      Lyonel-Feininger-Straße 27,
                      <br />
                      München BY 80807
                    </p>
                    <h2>Kontakt:</h2>
                    <p>E-Mail: info@kalbytes.de</p>
                    <h2>
                      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                    </h2>
                    <p>Igor Romanovsky</p>
                    <h2>Haftungsausschluss (Disclaimer)</h2>
                    <p>
                      <strong>Haftung für Inhalte</strong>
                    </p>
                    <p>
                      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                      eigene Inhalte auf diesen Seiten nach den allgemeinen
                      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                      Diensteanbieter jedoch nicht verpflichtet, übermittelte
                      oder gespeicherte fremde Informationen zu überwachen oder
                      nach Umständen zu forschen, die auf eine rechtswidrige
                      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                      Sperrung der Nutzung von Informationen nach den
                      allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                      Bekanntwerden von entsprechenden Rechtsverletzungen werden
                      wir diese Inhalte umgehend entfernen.
                    </p>
                    <p>
                      <strong>Haftung für Links</strong>
                    </p>
                    <p>
                      Unser Angebot enthält Links zu externen Webseiten Dritter,
                      auf deren Inhalte wir keinen Einfluss haben. Deshalb
                      können wir für diese fremden Inhalte auch keine Gewähr
                      übernehmen. Für die Inhalte der verlinkten Seiten ist
                      stets der jeweilige Anbieter oder Betreiber der Seiten
                      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                      der Verlinkung auf mögliche Rechtsverstöße überprüft.
                      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                      nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                      verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                      von Rechtsverletzungen werden wir derartige Links umgehend
                      entfernen.
                    </p>
                    <p>
                      <strong>Urheberrecht</strong>
                    </p>
                    <p>
                      Die durch die Seitenbetreiber erstellten Inhalte und Werke
                      auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                      Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                      Art der Verwertung außerhalb der Grenzen des
                      Urheberrechtes bedürfen der schriftlichen Zustimmung des
                      jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                      dieser Seite sind nur für den privaten, nicht
                      kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                      dieser Seite nicht vom Betreiber erstellt wurden, werden
                      die Urheberrechte Dritter beachtet. Insbesondere werden
                      Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                      trotzdem auf eine Urheberrechtsverletzung aufmerksam
                      werden, bitten wir um einen entsprechenden Hinweis. Bei
                      Bekanntwerden von Rechtsverletzungen werden wir derartige
                      Inhalte umgehend entfernen.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ImprintPage
